import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToasterService } from "../../services/toaster.service";

@Component({
	selector: "app-add-message",
	templateUrl: "./add-message.component.html",
	styleUrl: "./add-message.component.css",
})
export class AddMessageComponent implements OnInit {
	reason: string = "";
	isPrivate: boolean = false;

	constructor(
		public dialogRef: MatDialogRef<AddMessageComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private toaster: ToasterService
	) {}

	ngOnInit(): void {
		if (this.data) {
			this.isPrivate = this.isPrivate;
			this.reason = this.data?.rowData?.reason || "";
		}
	}

	submit(): void {
		let payload = {
			isPrivate: this.isPrivate,
			reason: this.reason,
		};
		if (!payload.reason) {
			this.toaster.warning("Please enter a reason");
			return;
		}
		this.dialogRef.close(payload);
	}

	close(): void {
		this.dialogRef.close();
	}
}
