<h2 mat-dialog-title class="headerColor" *ngIf="data.type == 'Verify' || data.type == 'Unverify'">{{ data.type }}
    License</h2>
<h2 mat-dialog-title class="headerColor" *ngIf="data.type != 'Verify' && data.type != 'Unverify'">{{ data.type }}
    Account</h2>


<div class="icon">
    <img src="../../../../assets/images/close.png" (click)="close()" class="close-icon" />
</div>
<mat-dialog-content>
    <div>
        <div class="example-full-width my-3">
            <label class="mb-2">Please provide the reason to
                {{ data.type == 'Verify' || data.type == 'Unverify' ? data.type + ' the license' : data.type + ' the account' }}:
            </label><span class="text-danger">*</span>
            <textarea class="form-control" [(ngModel)]="reason" name="reason" cols="30" rows="5"></textarea>
        </div>
        <div class="example-full-width my-3">
            <input class="mx-1" type="checkbox" [(ngModel)]="isPrivate" name="isPrivate">
            <label class="mb-2">Keep reason private </label>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end mb-2 m-auto">
    <div>
        <button mat-raised-button class="btnText" type="submit" color="primary" (click)="submit()">Submit</button>
        <button mat-raised-button (click)="close()">Close</button>
    </div>
</mat-dialog-actions>