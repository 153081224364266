<h2 mat-dialog-title class="headerColor text-center">Appointment Details</h2>
<div class="icon">
    <img src="../../../../assets/images/close.png" (click)="close()" class="close-icon" />
</div>
<mat-divider>
</mat-divider>
<mat-dialog-content>
    <!-- <p>{{this.userEmail}}</p> -->
    <div>
        <div>
            <div class="d-flex example-full-width my-2">
                <label>Practitioner Name</label>
                <div class="ps-2">{{data.rowData.practitionerName}}</div>
            </div>
            <div class="d-flex example-full-width my-2">
                <label>Patient Name:</label>
                <div class="ps-2">{{data.rowData.patientName}}</div>
            </div>
            <div class="d-flex example-full-width my-2">
                <label>Practitioner Service Type:</label>
                <div class="ps-2">{{data.rowData.patientServiceTypeName}}</div>
            </div>
            <div class="d-flex example-full-width my-2">
                <label>Appointment Date & Time:</label>
                <div class="ps-2">{{data.rowData.selectedAppointmentDate + " " + data.rowData.selectedAppointmentTime}}
                </div>
            </div>
            <div class="d-flex example-full-width my-2">
                <label>Status:</label>
                <div class="ps-2" [ngClass]="{
                    'text-completed': data?.rowData?.appointmentStatus === 'COMPLETED',
                    'text-active':(data?.rowData?.appointmentStatus === 'ACTIVE'),
                    'text-reschedule': (data?.rowData?.appointmentStatus ==='RESCHEDULED') ,
                    'text-cancel': (data?.rowData?.appointmentStatus === 'CANCELLED') ,
                  }">{{data.rowData.appointmentStatus}}</div>
            </div>
            <div class="d-flex example-full-width my-2" *ngIf="data.rowData.cancelReason!=='N/A'">
                <label>Cancel Reason:</label>
                <div class="ps-2">{{data.rowData.cancelReason }}</div>
            </div>

            <div class="d-flex example-full-width my-2" *ngIf="data.rowData.rescheduleReason!=='N/A'">
                <label>Reschedule Reason :</label>
                <div class="ps-2 text-break">{{data.rowData.rescheduleReason}}</div>
            </div>
        </div>

    </div>


</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="warn" class="m-auto mb-2" (click)="close()">Close</button>
</mat-dialog-actions>