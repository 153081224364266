import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToasterService } from '../../services/toaster.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrl: './details.component.css'
})
export class DetailsComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toaster: ToasterService
  ) {

  }

  formattedTime(date: string): string {
    return moment.tz(date, "DD-MM-YYYY HH:mm", "UTC").tz("Asia/Kolkata").format("DD-MM-YYYY hh:mm A");
  }

  ngOnInit(): void {
    console.log(this.data); 
  }

  close() {
    this.dialogRef.close();
  }
}

